import { log } from "../utils/logUtil";

export const initializeGA = (GA_MEASUREMENT_ID) => {
    if (!GA_MEASUREMENT_ID) {
      log('GTM_ID environment variable is not defined. Google Analytics will not be initialized.');
      return;
    }
  
    if (document.querySelector(`script[src*="gtag/js?id=${GA_MEASUREMENT_ID}"]`)) {
      return;
    }
  
    log('Loading gtag.js...');
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    document.head.appendChild(script);
  
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      window.gtag('js', new Date());
      window.gtag('config', GA_MEASUREMENT_ID);
  
      log('gtag.js loaded and initialized.');
    };
  };
  