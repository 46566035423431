import React, { useContext } from 'react'
import SessionService from '../services/session-service'

const userInfo = SessionService.getUserData() || null
const tokenInfo = SessionService.getTokenData() || null

const initialState = {
	userInfo,
	tokenInfo,
	isLogin: userInfo !== null && tokenInfo !== null,
}

const UserContext = React.createContext(initialState)

const reducer = (state, action) => {
	switch (action.type) {
		case 'set-user-info':
			return { ...state, userInfo: action.payload }
		case 'set-token-info':
			return { ...state, tokenInfo: action.payload }
		case 'set-is-loging':
			return { ...state, isLogin: action.payload }
		case 'clear-user-info':
			return { ...state, userInfo: null }
		case 'clear-token-info':
			return { ...state, tokenInfo: null }
		case 'clear-is-loging':
			return { ...state, isLogin: false }
		case 'set-user-context':
			return { ...state, ...action.payload }
		case 'reset-user-context':
			return { ...initialState }
		case 'LOGOUT':
			return { ...state, userInfo: null, tokenInfo: null, isLogin: false }
		default:
			return { ...state }
	}
}

function UserContextProvider(props) {
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const value = { ...state, state, dispatch }

	return (
		<UserContext.Provider value={value}>{props.children}</UserContext.Provider>
	)
}

const ContextConsumer = UserContext.Consumer

export { UserContext, UserContextProvider, ContextConsumer }

export const useAuth = () => useContext(UserContext);